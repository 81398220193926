<template>
  <v-layout v-if="getPermission('product:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-col md="10" offset-md="1" class="pb-0">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Error Code Information
              </h3>
            </v-card-title>
            <v-row>
              <v-col class="iv-custom-field" cols="8" md="8" sm="8">
                <v-card-text class="p-6 font-size-16">
                  <div class="d-flex">
                    <label
                      class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                      >Name</label
                    >
                    <label
                      class="pl-2 font-weight-700 font-size-16 width-100 required"
                      >Serial no</label
                    >
                  </div>
                  <div class="d-flex">
                    <v-text-field
                      dense
                      filled
                      placeholder="Name"
                      solo
                      flat
                      class="pr-2 pl-2 width-100"
                      color="cyan"
                      v-model.trim="productCreate.name"
                      :rules="[
                        validateRules.required(productCreate.name, 'Name'),
                        validateRules.minLength(productCreate.name, 'Name', 1),
                        validateRules.maxLength(
                          productCreate.name,
                          'Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      placeholder="Serial no"
                      solo
                      flat
                      class="pl-2 width-100"
                      v-model.trim="productCreate.serial_number"
                      :rules="[
                        validateRules.required(
                          productCreate.serial_number,
                          'Serial no'
                        ),
                        validateRules.minLength(
                          productCreate.serial_number,
                          'Serial no',
                          3
                        ),
                        validateRules.maxLength(
                          productCreate.serial_number,
                          'Serial no',
                          15
                        ),
                      ]"
                    ></v-text-field>
                  </div>
                  <div class="d-flex">
                    <label
                      class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                      >Brand</label
                    >
                  </div>
                  <div class="d-flex">
                    <v-autocomplete
                      :items="brandList"
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      placeholder="Brand"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="BrandData"
                      item-text="text"
                      item-value="text"
                      class="pl-2 width-100"
                      v-model.trim="productCreate.brand"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Brand(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>

                  <div class="d-flex">
                    <label
                      class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                      >Model
                    </label>
                  </div>

                  <div class="d-flex">
                    <!-- <v-text-field
                    dense
                    filled
                    placeholder="Part Number"
                    solo
                    flat
                    color="cyan"
                    class="pr-2 width-100"
                    :rules="[
                      validateRules.minLength(
                        productCreate.part_number,
                        'Part Number',
                        1
                      ),
                      validateRules.maxLength(
                        productCreate.part_number,
                        'Part Number',
                        100
                      ),
                    ]"
                    v-model.trim="productCreate.part_number"
                  ></v-text-field> -->

                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="categoryList"
                      placeholder="Model"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageCategoryDialog = true"
                      item-text="text"
                      item-value="text"
                      class="pl-2 width-100"
                      v-model.trim="productCreate.model"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Model(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-card-text>
              </v-col>
              <v-col
                class="iv-custom-field"
                cols="4"
                md="4"
                sm="4"
                v-on:mouseleave="imageTemplate = false"
                v-on:mouseover="imageTemplate = true"
              >
                <template>
                  <v-img
                    :lazy-src="$defaultImage"
                    :src="getProfileImage ? getProfileImage : $defaultImage"
                    max-height="200"
                    max-width="200"
                    height="200px"
                    width="200px"
                    class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
                  >
                  </v-img>
                  <div class="margin-auto">
                    <v-btn
                      depressed
                      class="my-4 custom-bold-button width-100"
                      outlined
                      small
                      v-on:click="selectImage"
                      color="cyan"
                      :loading="imageLoading"
                      :disabled="imageLoading"
                    >
                      <template v-if="getProfileImage">Change Image</template>
                      <template v-else>Upload Image</template>
                    </v-btn>
                    <div class="d-none">
                      <v-file-input
                        ref="uploadImage"
                        v-on:change="updateProfileImage"
                        :rules="imageRules"
                        accept="image/png, image/jpeg, image/jpg"
                      ></v-file-input>
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-card>

          <br />

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Description Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <label class="font-weight-700 font-size-16">Description</label>
              <v-textarea
                dense
                filled
                placeholder="Description"
                solo
                flat
                row-height="20"
                auto-grow
                color="cyan"
                v-model.trim="productCreate.description"
                :rules="[
                  validateRules.minLength(
                    productCreate.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.description,
                    'Description',
                    1024
                  ),
                ]"
                v-on:keypress="(e) => manageLimitdescr(e)"
                v-on:paste="(e) => onPaste(e)"
              ></v-textarea>
              <v-col md="12" class="text-right"
                >{{
                  productCreate.description
                    ? productCreate.description.length
                    : 0
                }}/200</v-col
              >
            </v-card-text>
          </v-card>

          <template>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                placeholder="UPC"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.upc, 'UPC', 1),
                  validateRules.maxLength(productCreate.upc, 'UPC', 100),
                ]"
                v-model.trim="productCreate.upc"
                class="pr-2 width-100"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                placeholder="SKU"
                solo
                flat
                color="cyan"
                :rules="[
                  validateRules.minLength(productCreate.sku, 'SKU', 1),
                  validateRules.maxLength(productCreate.sku, 'SKU', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.sku"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                placeholder="HSN Code"
                solo
                flat
                :rules="[
                  validateRules.minLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    100
                  ),
                ]"
                class="pr-2 width-100"
                v-model.trim="productCreate.hsn_code"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                placeholder="EAN"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.ean, 'EAN', 1),
                  validateRules.maxLength(productCreate.ean, 'EAN', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.ean"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                placeholder="ISBN"
                solo
                flat
                color="cyan"
                class="pr-2 width-100"
                :rules="[
                  validateRules.minLength(productCreate.isbn, 'ISBN', 1),
                  validateRules.maxLength(productCreate.isbn, 'ISBN', 100),
                ]"
                v-model.trim="productCreate.isbn"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                placeholder="Part Number"
                solo
                flat
                color="cyan"
                class="pl-2 width-100"
                :rules="[
                  validateRules.minLength(
                    productCreate.part_number,
                    'Part Number',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.part_number,
                    'Part Number',
                    100
                  ),
                ]"
                v-model.trim="productCreate.part_number"
              ></v-text-field>
            </div>
          </template>
          <div class="d-flex" v-if="false">
            <label class="custom-form-label">Tax Preference</label>
            <v-radio-group
              class="pt-0 mt-0 mb-2"
              dense
              row
              color="cyan"
              v-model.trim="productCreate.taxable"
            >
              <v-radio label="Taxable" color="cyan" :value="true"></v-radio>
              <v-radio
                label="Non-Taxable"
                color="cyan"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </div>

          <template> </template>
          <template>
            <!-- $hasWarranty && getPermission('warranty:create') -->
            <div v-if="false">
              <v-checkbox
                class="mt-0 width-100"
                color="cyan"
                v-model.trim="warrantyActive"
                v-on:change="checkWarrrnty($event)"
              >
                <template v-slot:label>
                  <label class="custom-form-label m-0 width-100"
                    >Warranty</label
                  >
                </template>
              </v-checkbox>
              <!-- <template v-if="warrantyActive">
                <div class="d-flex">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Duration</label
                  >

                  <label class="pl-2 font-weight-700 font-size-16 width-100"
                    >Type</label
                  >
                </div>
                <template v-for="(warranty, index) in productCreate.warranty">
                  <div class="d-flex" :key="index">
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      color="cyan"
                      placeholder="Duration"
                      solo
                      flat
                      class="pr-2 width-100"
                      v-model.trim="warranty.value"
                    ></v-text-field>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      color="cyan"
                      hide-details
                      solo
                      flat
                      item-color="cyan"
                      class="pl-2 pr-2 width-100"
                      v-model.trim="warranty.field"
                      placeholder="Type"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                    <div v-if="false" class="pl-2 width-50">
                      <template v-if="index > 0">
                        <v-btn
                          class="mx-2"
                          color="danger"
                          dark
                          fab
                          small
                          v-on:click="removeWarranty(index, warranty.id)"
                        >
                          <v-icon dark> mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn
                          class="mx-2"
                          color="cyan"
                          dark
                          fab
                          small
                          v-on:click="pushWarranty"
                        >
                          <v-icon dark> mdi-plus</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                </template>
              </template> -->
              <template v-if="warrantyActive">
                <div class="d-flex">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Duration</label
                  >

                  <label class="pl-2 font-weight-700 font-size-16 width-100"
                    >Type</label
                  >
                </div>
                <!-- <template v-for="(warranty, index) in productCreate.warranty"> -->
                <div class="d-flex">
                  <v-text-field
                    dense
                    v-mask="'###'"
                    filled
                    color="cyan"
                    placeholder="Duration"
                    solo
                    flat
                    class="pr-2 width-100"
                    v-model.trim="productCreate.warranty_duration"
                  ></v-text-field>
                  <v-select
                    :items="warrantyDurationTypes"
                    dense
                    filled
                    color="cyan"
                    hide-details
                    solo
                    flat
                    item-color="cyan"
                    class="pl-2 pr-2 width-100"
                    v-model.trim="productCreate.warranty_type"
                    placeholder="Type"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <div v-if="false" class="pl-2 width-50">
                    <template v-if="index > 0">
                      <v-btn
                        depressed
                        class="mx-2"
                        color="danger"
                        dark
                        fab
                        small
                        v-on:click="removeWarranty(index, warranty.id)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        depressed
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        small
                        v-on:click="pushWarranty"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </div>
                </div>
                <!--  </template> -->
              </template>
            </div>

            <br />
            <v-card
              flat
              class="custom-grey-border remove-border-radius"
              v-if="false"
            >
              <!-- <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Attachments
                </h3>
              </v-card-title> -->
              <!-- <v-card-text class="p-6 font-size-16">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Images
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :file-type="true"
                      :attachments="productCreate.product_images"
                      v-on:file:updated="updateImages"
                      acceptFile="image/png, image/jpeg, image/jpg"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text> -->

              <v-card-text class="p-6 font-size-16">
                <div>
                  <label class="custom-form-label width-100"
                    >Attachments
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 attachments are not allowed</span>
                    </v-tooltip>
                  </label>
                  <!-- <v-col md="12" class="py-0">
                    <table
                      width="100%"
                      style="table-layout: fixed"
                      class="my-auto py-0 align-items-center"
                    >
                      <tr
                        v-for="(row, index) in files"
                        :key="`create-visit-attachment-${index}`"
                      >
                        <td class="">
                          <v-file-input
                            hide-details
                            class="px-0"
                            dense
                            filled
                            solo
                            flat
                            v-model="row.file"
                            placeholder="Select File"
                            prepend-icon=""
                            prepend-inner-icon="$file"
                            v-on:change="updateFile(index, $event)"
                            v-on:click:clear="updateFile(index, $event)"
                            color="cyan"
                          />
                        </td>
                        <td class="py-0">
                          <v-text-field
                            hide-details
                            class="px-0"
                            v-model="row.name"
                            placeholder="Name"
                            dense
                            filled
                            solo
                            flat
                            color="cyan"
                          />
                        </td>
                        <td class="px-0 py-0" width="5%" align="center">
                          <v-btn
                            :disabled="files.length < 2"
                            v-on:click="removeFile(index)"
                            icon
                            class="w-100"
                            color="red lighten-1"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="" colspan="3">
                          <v-btn
                            color="cyan white--text"
                            class="custom-bold-button"
                            depressed
                            v-on:click="addMore()"
                            tile
                            >Add More</v-btn
                          >
                        </td>
                      </tr>
                    </table>
                  
                  </v-col> -->

                  <v-layout
                    class="p-4 border-bottom-light-grey min-height-57px"
                  >
                    <v-flex class="font-level-3-bold my-auto">
                      <span class="detail-svg-icon mr-2">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/files-color.svg')"
                        />
                      </span>
                      <template> Files </template>
                    </v-flex>
                    <v-flex class="text-right">
                      <v-btn
                        v-on:click="add_document_dialog = true"
                        color="cyan text-white"
                        class="ml-2"
                        depressed
                        tile
                        ><v-icon small left>mdi-playlist-plus</v-icon>Attach
                        File
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <DocumentwithName
                    :document-dialog="add_document_dialog"
                    v-on:close="add_document_dialog = false"
                    v-on:success="getFiles()"
                    :document-type="55"
                    :document-id="55"
                    :extensive="extensive"
                    :parent-type="typeParent"
                    :parent-type-id="typeParentId"
                    module-type="error-code"
                    v-on:getUploadedIds="getUploadedIdsData"
                  >
                  </DocumentwithName>

                  <!-- <div>getUploadedIds
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :attachments="productCreate.product_attachments"
                      v-on:file:updated="updateAttachments"
                      :file-type="true"
                    ></FileTemplate>
                  </div> -->
                </div>
              </v-card-text>
            </v-card>

            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Attachments
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <FileUpload v-model="productCreate.files"> </FileUpload>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </perfect-scrollbar>
    </v-col>
    <v-col
      v-if="false"
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
    <ManageBrand
      :b-dialog="manageBrandDialog"
      :brand="brandList"
      v-on:close-dialog="manageBrandDialog = false"
      v-on:get-product-brand="getOptions"
    ></ManageBrand>
    <ManageManufacturer
      :dialog="manageManufacturerDialog"
      :manufacturer="manufacturerList"
      v-on:close-dialog="manageManufacturerDialog = false"
      v-on:get-product-manufacturer="getOptions"
    ></ManageManufacturer>
    <!-- <ManageCategory
      product
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory> -->
    <ManageModels
      product
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    >
    </ManageModels>
    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
  </v-layout>
</template>

<script>
import { toNumber, round } from "lodash";
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
//import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
//import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageModels from "@/view/pages/partials/Manage-ErrorCode-Models.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
//import DatePicker from "@/view/components/DatePicker";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";
import moment from "moment";
import DocumentwithName from "@/view/pages/DocumentwithName";
import ApiService from "@/core/services/api.service";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import FileUpload from "@/view/components/FileUpload";

import {
  QUERY,
  PATCH,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
// import { find } from "lodash";
import { cloneDeep, filter, find } from "lodash";

export default {
  name: "product-create",
  mixins: [
    CommonMixin,
    WarrantyMixin,
    ProductMixin,
    ValidationMixin,
    FileManagerMixin,
  ],
  props: {
    extensive: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: null,
    },
    typeParentId: {
      type: Number,
      default: null,
    },
    typeParent: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: null,
    },
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    // FileTemplate,
    // DatePicker,
    DocumentwithName,
    ManageBrand,
    ManageManufacturer,
    //ManageCategory,
    ManageModels,
    ManageUnit,
    FileUpload,
  },
  data() {
    return {
      imageTemplate: false,
      imageLoading: false,
      docIds: [],
      files: [
        {
          file: null,
          name: null,
        },
      ],
      attachLoading: false,
      add_document_dialog: false,
      proDuplicateId: null,
      timeoutLimit: 500,
      pageLoading: false,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      //  supplierList: new Array(),
      vendorList: [],
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      manageUnitDialog: false,
      warrantyActive: false,
      productCreate: {
        profile: null,
        uploadedFileIds: [],
        name: null,
        model: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        serial_number: null,
        part_number: null,
        hsn_code: null,
        description: null,
        warranty_type: null,
        warranty_duration: 0,
        directory: null,
        supplier: null,
        brand: null,
        barcode: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "goods",
        purchase_cost: null,
        selling_cost: null,
        project_price: null,
        threshold_price: null,
        /*  quantity:1, */
        commission_rate: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        images: [],
        attachments: [],
        product_images: [],
        product_attachments: [],
        website_url: null,
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
        priceList: [
          {
            id: null,
            unit_price: 0,
            selling_price: 0,
            discount_price: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: null,
          },
        ],
        suppliers: [
          {
            id: null,
            uuid: null,
            product_id: null,
            supplier_price: null,
            part_number: null,
          },
        ],
        files: [],
      },

      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        this.productCreate.barcode = param.barcode ? param.barcode : null;
        this.$nextTick(() => {
          if (param.warranty_type && param.warranty_duration) {
            this.warrantyActive = true;
          }
        });
        if (this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }
      },
    },
    /* 
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        console.log(this.productCreate, "this.productCreate");
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }
        if (this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }

        this.$nextTick(() => {
          if (
            param.warranty &&
            param.warranty.length &&
            param.warranty[0] &&
            param.warranty[0].value
          ) {
            this.warrantyActive = true;
           
          }
        });
      },
      }, */
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
          //  _this.$emit("filesTake", _this.files);
        }, _this.timeoutLimit);
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      // single image upload
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          let data = _this.lodash.head(response);
          if (data) {
            _this.productCreate.profile = data.file.url;
          } else {
            _this.productCreate.profile = null;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },

    getUploadedIdsData(param) {
      this.productCreate.uploadedFileIds = param;
      //console.log(this.productCreate.uploadedFileIds);
    },
    updateFile(index, file, value, data) {
      if (value == "keyword") {
        if (file && file.name) {
          data[index].name = file.name.split(".").slice(0, -1).join(".");
          data[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          data[index].name = null;
          data[index].suffix = null;
        }
      } else {
        if (file && file.name) {
          this.files[index].name = file.name.split(".").slice(0, -1).join(".");
          this.files[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          this.files[index].name = null;
          this.files[index].suffix = null;
        }
      }
    },
    addMore() {
      this.files.push({
        file: null,
        name: null,
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },

    updateFiles(param) {
      if (param) {
        const max_size = toNumber(this.$uploadSizeLIMIT);
        const max_file = toNumber(this.$uploadLIMIT);
        for (let i = 0; i < param.length; i++) {
          const size = round(toNumber(param[i].size / 1024), 2);
          if (size <= max_size && this.files.length < max_file) {
            this.files.push(param[i]);
          }
        }
      }
    },
    getFileSize(size) {
      return round(toNumber(size) / 1024, 2);
    },
    // removeFile(index) {
    //   this.files.splice(index, 1);
    //   this.$refs["upload-file"].value = null;
    // },
    maxFileSize() {
      return round(toNumber(this.$uploadSizeLIMIT) / 1024, 2);
    },
    selectFile() {
      this.$refs["upload-file"].reset();
      this.$refs["upload-file"].$refs["input"].value = null;
      this.$nextTick(() => {
        this.$refs["upload-file"].$refs["input"].click();
      });
    },
    attachFile() {
      const params = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        params.append(`file[${i}]`, this.files[i]);
      }

      params.append("description", this.description);

      this.attachLoading = true;

      ApiService.setHeader();
      ApiService.upload(`${this.type}/file`, params)
        .then(() => {
          this.getFiles();
          this.$refs["upload-file"].value = null;
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
        });
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    getFiles() {
      ApiService.setHeader();
      // ApiService.get(`documents/${this.type}/${this.typeId}?module_type=error-code`)
      let prod_id = this.productId ? this.productId : 0;
      ApiService.get(`documents/55/${prod_id}?module_type=error-code`)
        .then(({ data }) => {
          this.dbFiles = data.data;
          this.init();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    doAction(row, param) {
      switch (param) {
        case "download":
          window.open(row.file.url, "_blank");
          break;
        case "delete":
          this.id = row.id;
          this.deleteDialog = true;
          break;
      }
    },
    deleteFile() {
      if (!this.id) {
        return false;
      }
      this.deleteLoading = true;
      ApiService.setHeader();
      ApiService.delete(`delete-documents/${this.id}`)

        .then(() => {
          this.getFiles();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    checkWarrrnty(event) {
      if (!event) {
        this.productCreate.warranty_type = null;
        this.productCreate.warranty_duration = 0;
      } else {
        if (this.updateMode) {
          this.$emit("get:warrantyActive", true);
        }
        this.productCreate.warranty_type = this.productCreate.warranty_type;
        this.productCreate.warranty_duration =
          this.productCreate.warranty_duration;
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.productCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.productCreate.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.productCreate.description &&
        this.productCreate.description.length > 199
      ) {
        e.preventDefault();
      }
    },

    addSupplier() {
      this.productCreate.suppliers.push({
        id: null,
        supplier_id: null,
        part_number: null,
        supplier_price: null,
      });
    },
    // removeSupplier(index, data) {
    //   if (data.id) {
    //     const param = { id: data.id };
    //     RemoveSuppliers(this.uuid, param).then(() => {
    //       this.suppliers.splice(index, 1);
    //     });
    //   } else {
    //     this.suppliers.splice(index, 1);
    //   }
    // },
    removeSupplier(index, data) {
      console.log(data, "data");

      this.productCreate.suppliers.splice(index, 1);

      const _this = this;

      _this.formLoading = true;
      //  const param = data.id;

      if (this.productId > 0) {
        _this.$store
          .dispatch(PATCH, {
            url: "product/" + this.productId + "/inventory_supplier",
            data: {
              id: data.id,
            },
          })

          .then(() => {})
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
      //const param = data.id;
      /* ApiService.PUT(`product/${param}/inventory_supplier`, )
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			}); */

      // console.log(data, "data");
      // if (data.id) {
      //  // console.log(data.id,"dataid");
      // //	const param = { id: data.id };
      // 	// RemoveSuppliers(this.uuid, param).then(() => {
      // 	// 	this.suppliers.splice(index, 1);
      // 	// });

      // } else {
      // 	this.productCreate.suppliers.splice(index, 1);
      // }
    },
    discountPriceChange(index) {
      if (
        this.productCreate.priceList[index].discount_price >
        this.productCreate.priceList[index].selling_cost
      ) {
        this.productCreate.priceList[index].discount_price =
          this.productCreate.priceList[index].selling_cost;
      }
    },
    discountSelling(index) {
      if (
        this.productCreate.priceList[index].selling_cost <
        this.productCreate.priceList[index].discount_price
      ) {
        this.productCreate.priceList[index].selling_cost =
          this.productCreate.priceList[index].discount_price;
      }
    },
    addPrice() {
      let previous_end_date = null; // moment().format("YYYY-MM-DD");
      if (
        this.productCreate &&
        this.productCreate.priceList &&
        this.productCreate.priceList.length < 1
      ) {
        previous_end_date = moment().format("YYYY-MM-DD");
      }
      this.productCreate.priceList.push({
        id: null,
        unit_price: 0,
        selling_cost: 0,
        discount_price: 0,
        start_date: previous_end_date,
        end_date: null,
      });
    },

    removePrice(index) {
      if (index >= 1) {
        this.productCreate.priceList[index - 1].end_date = null;
      }
      this.productCreate.priceList.splice(index, 1);
    },

    Categorydata() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    BrandData() {
      this.manageBrandDialog = true;
      this.getOptions();
    },
    UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    },
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    updateImages(param) {
      this.productCreate.images = param;
    },
    updateAttachments(param) {
      this.productCreate.attachments = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "error-code/options",
          data: { product_type: null },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data) {
            _this.barcodeData = response.data.barcode;
          }
          /* if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode &&
            !this.productCreate.barcode
          ) {
            _this.productCreate.barcode = response.data.barcode;
          } */

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          // if (
          //   _this.lodash.isEmpty(response.data) === false &&
          //   response.data.supplier
          // ) {
          //   _this.supplierList = response.data.supplier;
          // }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
            //  console.log(_this.unitList);
          }

          if (!_this.updateMode) {
            if (
              !_this.productId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            /*  if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              _this.productCreate.description =
                _this.barcodeSetting.description;
            } */

            _this.productCreate.serial_number = response.data.serial_number;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  computed: {
    ...mapGetters(["errors", "localDB"]),
    supplier_list() {
      return this.localDB("suppliers", []);
    },
    supplierList: () => {
      return function (id) {
        //console.log(id,'id');
        let _vendorList = cloneDeep(this.vendorList);
        // console.log(_vendorList,'_vendorList')
        //  console.log(this.productCreate.suppliers,'111')
        const newArray = filter(_vendorList, (row) => {
          if (row.value != id) {
            if (
              !find(
                this.productCreate.suppliers,
                (inner_row) => inner_row.supplier_id == row.value
              )
            ) {
              return row;
            }
          } else {
            return row;
          }
        });
        return newArray;
      };
    },

    isStartDateDisable: () => {
      return function (index) {
        if (this.productCreate.priceList.length == index + 1) {
          return false;
        }
        return true;
      };
    },
    end_min_date: () => {
      return function (date, index) {
        let _priceList = cloneDeep(this.productCreate.priceList);
        if (index > 0) {
          const min_end_date = _priceList[index - 1].start_date;
          this.productCreate.priceList[index - 1].end_date = date;
          return min_end_date;
        }
        return moment().format("YYYY-MM-DD");
      };
    },
    getProfileImage() {
      //return '';
      return this.lodash.isEmpty(this.productCreate.profile) === false
        ? this.productCreate.profile
        : null;
    },
    //   isDisable() {
    // 	const exists =  this.priceList.find((row) => {
    // 		return !row.start_date;
    // 	});
    // 	return exists ? true : false;
    // },
  },
  mounted() {
    this.type = 55;
    this.$store.dispatch(SET_LOCAL_DB).then(() => {
      this.vendorList = this.$store.state.config.localDB["data"]["suppliers"]; //this.localDB("suppliers", []);
      //console.log(this.$store.state.config.localDB['data']['suppliers'],"2222");
    });

    this.getOptions();
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
